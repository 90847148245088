<section id="navigation" class="header">
    <img *ngIf="mainLogo" class="main-logo" [ngClass]="{'animate__fadeOut': burgerButton, 'animate__fadeIn': menuOut}"
        src="./assets/img/logo.png" alt="MY_LOGO">
        <img class="bigViewLogo" src="./../../../assets/img/logo-mobile-navabr.png">
    <div class="links">
        <a href="#about-me">{{ 'HEADER.ABOUT_ME' | translate }}</a>
        <a href="#skill">{{ 'HEADER.SKILL_SET' | translate }}</a>
        <a href="#projects">{{ 'HEADER.MY_WORK' | translate }}</a>
    </div>
    <div (click)="toggleBurgerMenu()" #btn id="burger" class="btn not-active">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="mobile-switch">
        <div class="toggle-switch">
            <input (click)="checkValue()" [(ngModel)]="isChecked" class="toggle-input" id="toggle" type="checkbox" />
            <!-- <input type="checkbox"  class="toggle-input" id="toggle" [(ngModel)]="isChecked" (change)="onCheckboxChange($event)"> -->

            <label class="toggle-label" for="toggle"></label>
            <div class="flags">
                <img src="./assets/img/usaFlag.png">
                <img src="./assets/img/germanFlag.png">
            </div>
        </div>
    </div>
</section>

<app-mobile-navigation *ngIf="burgerButton"
    [ngClass]="{'animate__fadeInRight': burgerButton, 'animate__fadeOutRight': menuOut}"
    class="mobile-nav"></app-mobile-navigation>