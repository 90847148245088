<section *ngIf="!translation">

    <div>
        <h2 class="wp-block-heading">Impressum</h2>

        <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der
            Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
            Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>

        <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur
            „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter
            Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das
            Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das
            Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
            Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>

        <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer
            und
            Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit
            anderen
            über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von
            uns
            zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit
            hierdurch
            Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
        <p class="paddingSmall">Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>

        <p>I. Informationen über uns als Verantwortliche<br>II. Rechte der Nutzer und Betroffenen<br>III. Informationen
            zur
            Datenverarbeitung</p>

        <h3 class="wp-block-heading paddingBig">I. Informationen über uns als Verantwortliche</h3>

        <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>

        Florian Schoenfelder<br>Gelbsand 21<br>Cuxhaven

        <p>Telefon: 015142110745<br>E-Mail: info(at)florian-schoenfelder.de

        </p>
        <p>Datenschutzbeauftragte/r beim Anbieter ist:</p>
        <br>
        Florian Schoenfelder

        <h3 class="wp-block-heading paddingBig">II. Rechte der Nutzer und Betroffenen</h3>

        <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das
            Recht</p>

        <ul class="wp-block-list">
            <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten,
                auf
                weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);
            </li>

            <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16
                DSGVO);
            </li>

            <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ,
                soweit
                eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der
                Verarbeitung
                nach Maßgabe von Art. 18 DSGVO;</li>
            <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten
                an
                andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
            <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden
                Daten
                durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch
                Art.
                77 DSGVO).</li>
        </ul>
        <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter
            offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der
            Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung
            besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden
            ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>

        <p><strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die
                künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von
                Art.
                6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung
                zum
                Zwecke der Direktwerbung statthaft.</strong></p>

        <h3 class="wp-block-heading paddingBig">III. Informationen zur Datenverarbeitung</h3>
        <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der
            Zweck
            der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen
            und
            nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>
        <h4 class="jet-listing-dynamic-field__content paddingSmall">Kontaktanfragen / Kontaktmöglichkeit</h4>
        <p>Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen
            Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre
            Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt
            beantworten.</p>
        <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
        <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden
            Vertragsabwicklung.</p>
        <h4 class="jet-listing-dynamic-field__content paddingSmall">LinkedIn</h4>
        <p>LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, ein Tochterunternehmen der
            LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.</p>
        <p>Datenschutzerklärung: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
                rel="noopener nofollow">LinkedIn privacy policy</a></p>

        <p><a href="https://www.generator-datenschutzerklärung.de" target="_blank"
                rel="noopener">Muster-Datenschutzerklärung</a> der <a href="https://www.bewertung-löschen24.de"
                rel="nofollow noopener" target="_blank">Anwaltskanzlei Weiß &amp; Partner</a></p>

    </div>


</section>

<img class="ball" src="./assets/img/blue-ball.png">



<!-- ENGLISH BELOW -->


<section *ngIf="translation">

    <div>
        <h2 class="wp-block-heading">Imprint</h2>
        <p>Personal data (usually referred to just as „data“ below) will only be processed by us to the extent necessary
            and
            for the purpose of providing a functional and user-friendly website, including its contents, and the
            services
            offered there.</p>
        <p>Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter
            referred
            to as the „GDPR“), „processing“ refers to any operation or set of operations such as collection, recording,
            organization, structuring, storage, adaptation, alteration, retrieval, consultation, use, disclosure by
            transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure,
            or
            destruction performed on personal data, whether by automated means or not.</p>
        <p>The following privacy policy is intended to inform you in particular about the type, scope, purpose,
            duration,
            and legal basis for the processing of such data either under our own control or in conjunction with others.
            We
            also inform you below about the third-party components we use to optimize our website and improve the user
            experience which may result in said third parties also processing data they collect and control.</p>
        <p>Our privacy policy is structured as follows:</p>
        <p>I. Information about us as controllers of your data<br>II. The rights of users and data subjects<br>III.
            Information about the data processing</p>
        <h3 class="wp-block-heading">I. Information about us as controllers of your data</h3>
        <p>The party responsible for this website (the „controller“) for purposes of data protection law is:</p>
        <br>Florian Schoenfelder<br>Gelbsand 21<br>Cuxhaven
        <p>Telefon: 015142110745<br>E-Mail: info(at)florian-schoenfelder.de
        </p>
        <p>The controller’s data protection officer is:</p>
        <br>
        Florian Schoenfelder
        <h3 class="wp-block-heading">II. The rights of users and data subjects</h3>
        <p>With regard to the data processing to be described in more detail below, users and data subjects have the
            right
        </p>
        <ul class="wp-block-list">
            <li>to confirmation of whether data concerning them is being processed, information about the data being
                processed, further information about the nature of the data processing, and copies of the data (cf. also
                Art. 15 GDPR);</li>
            <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li>
            <li>to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively, if
                further
                processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18
                GDPR;
            </li>
            <li>to receive copies of the data concerning them and/or provided by them and to have the same transmitted
                to
                other providers/controllers (cf. also Art. 20 GDPR);</li>
            <li>to file complaints with the supervisory authority if they believe that data concerning them is being
                processed by the controller in breach of data protection provisions (see also Art. 77 GDPR).</li>
        </ul>
        <p>In addition, the controller is obliged to inform all recipients to whom it discloses data of any such
            corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR.
            However,
            this obligation does not apply if such notification is impossible or involves a disproportionate effort.
            Nevertheless, users have a right to information about these recipients.</p>
        <p><strong>Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller’s
                future
                processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data
                processing for the purpose of direct advertising is permissible.</strong></p>
        <h3 class="wp-block-heading">III. Information about the data processing</h3>
        <p>Your data processed when using our website will be deleted or blocked as soon as the purpose for its storage
            ceases to apply, provided the deletion of the same is not in breach of any statutory storage obligations or
            unless otherwise stipulated below.</p>
        <h4 class="jet-listing-dynamic-field__content">Contact</h4>
        <p>If you contact us via email or the contact form, the data you provide will be used for the purpose of
            processing
            your request. We must have this data in order to process and answer your inquiry; otherwise we will not be
            able
            to answer it in full or at all.</p>
        <p>The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.</p>
        <p>Your data will be deleted once we have fully answered your inquiry and there is no further legal obligation
            to
            store your data, such as if an order or contract resulted therefrom.</p>
        <h4 class="jet-listing-dynamic-field__content">LinkedIn</h4>
        <p>LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn
            Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.</p>
        <p>Privacy Policy: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
                rel="noopener nofollow">LinkedIn privacy policy</a></p>
        <p><a href="https://www.generator-datenschutzerklärung.de" target="_blank" rel="noopener">Model Data Protection
                Statement</a> for <a href="https://www.bewertung-löschen24.de" rel="nofollow noopener"
                target="_blank">Anwaltskanzlei Weiß &amp; Partner</a></p>

    </div>

</section>