<section class="aot-Frame">
    <div class="maxWidth">
        <img class="left-top-ball" src="./assets/img/blue-ball.png">
        <img class="aot-bottom-centered-ball" src="./assets/img/blue-ball.png">

        <div class="image-cointainer">
            <img class="image-of-myself" src="./../../../assets/img/new2.png">
        </div>
        <div class="self-introduce-container">
            <div class="greeting-myself">
                <span id="myName"></span><br>
                <div>
                    <span id="span1"></span>
                    <span id="span2"></span>
                </div>

            </div>
            <div class="links-container">
                <div class="scroll-line">
                    <span>Scroll</span>
                    <div class="line"></div>
                </div>
                <div class="my-links">
                    <img (click)="moveTo('github')" src="./assets/img/social1.png">
                    <img (click)="moveTo('email')" src="./assets/img/social2.png">
                    <img (click)="moveTo('linkedin')" src="./assets/img/social3.png">
                </div>
            </div>
        </div>
    </div>


</section>