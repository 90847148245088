<section class="headline">
    <h1 id="headline-myWork">{{ 'MYWORK.HEADLINE' | translate }}</h1>
    <span>{{ 'MYWORK.SECTION_DESCRIPTION' | translate }}</span>
</section>

<section class="myWorkBoxes" *ngFor="let item of myWork; let i = index">
    <img id="img{{ i }}" src="./assets/img/my-work-section/{{ item.img }}" alt="Pokedex-FRAME">
    <div class="myWorkDescription">
        <h1>{{ item.title }}</h1>
        <p>{{ item.languages }}</p>
        <span *ngIf="translation">{{ item.descriptionEN }}</span>
        <span *ngIf="!translation">{{ item.descriptionDE }}</span>
        <div class="buttonFrame">
            <button (click)="openProject(item.link)">Live Test</button>
            <button (click)="openGithub(item.github)">
                <span></span>
                Github
            </button>
        </div>
    </div>
</section>

<img class="blue-ball ball1" style="width: 108px; right: 20%;" src="./assets/img/blue-ball.png">
<img class="blue-ball ball2" style="width: 200px; height: 200px; top: 100px; right: 50px; z-index: -11;" src="./assets/img/blue-ball.png">
<img class="blue-ball ball3" style="width: 394px; height: 394px; left: -197px; top: 580px; z-index: -12;" src="./assets/img/blue-ball.png">
<img class="blue-ball ball4" style="width: 684px; height: 684px; right: -250px; top: 690px; z-index: -13;" src="./assets/img/blue-ball.png">
<img class="blue-ball ball5" style="width: 120px; height: 120px; left: 124px; top: 1460px; z-index: -14;" src="./assets/img/blue-ball.png">
