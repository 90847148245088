<img class="blue-ball" src="./assets/img/blue-ball.png">
<section>
    <p id="work-together">{{ 'ABOUTME.GREETING' | translate }}</p>
    <div class="about-me-text">
        <span>{{ 'ABOUTME.INTRODUCTION' | translate }}</span><br>
             <br>
             <span>{{ 'ABOUTME.BE_TOGETHER' | translate }}</span>
             <br>
             <br>

        <button (click)="scrollTo('contact')">
            <span></span>
            {{ 'ABOUTME.BUTTON' | translate }}
        </button>
        
    </div>
</section>
