<section *ngIf="translation">

    <div>
        <h1>Privacy Policy</h1>
        <p>Last updated: July 29, 2024</p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
            information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>
        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
            collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been
            created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
                target="_blank">Free Privacy Policy Generator</a>.</p>
        <h2 class="paddingBig">Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.</p>
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
            <li>
                <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                    Service.</p>
            </li>
            <li>
                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                    with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity
                    interest or other securities entitled to vote for election of directors or other managing authority.
                </p>
            </li>
            <li>
                <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                    &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Portfolio.</p>
            </li>
            <li>
                <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                    device by a website, containing the details of Your browsing history on that website among its many
                    uses.</p>
            </li>
            <li>
                <p><strong>Country</strong> refers to: Niedersachsen, Germany</p>
            </li>
            <li>
                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
                    or a digital tablet.</p>
            </li>
            <li>
                <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                    individual.</p>
            </li>
            <li>
                <p><strong>Service</strong> refers to the Website.</p>
            </li>
            <li>
                <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                    of the Company. It refers to third-party companies or individuals employed by the Company to
                    facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                    to the Service or to assist the Company in analyzing how the Service is used.</p>
            </li>
            <li>
                <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
                    the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </p>
            </li>
            <li>
                <p><strong>Website</strong> refers to Portfolio, accessible from <a
                        href="https://florian-schoenfelder.de/" rel="external nofollow noopener"
                        target="_blank">https://florian-schoenfelder.de/</a></p>
            </li>
            <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                    legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </p>
            </li>
        </ul>
        <h2 class="paddingBig">Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that
            can be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:</p>
        <ul>
            <li>
                <p>Email address</p>
            </li>
            <li>
                <p>First name and last name</p>
            </li>
            <li>
                <p>Phone number</p>
            </li>
            <li>
                <p>Usage Data</p>
            </li>
        </ul>
        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time
            spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p>When You access the Service by or through a mobile device, We may collect certain information automatically,
            including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.</p>
        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the
            Service by or through a mobile device.</p>
        <h4>Tracking Technologies and Cookies</h4>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
            to improve and analyze Our Service. The technologies We use may include:</p>
        <ul>
            <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can
                instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You
                do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted
                Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
            <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who have visited those pages or opened an
                email and for other related website statistics (for example, recording the popularity of a certain
                section and verifying system and server integrity).</li>
        </ul>
        <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your
            personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You
            close Your web browser. Learn more about cookies on the <a
                href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
                target="_blank">Free Privacy Policy website</a> article.</p>
        <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
        <ul>
            <li>
                <p><strong>Necessary / Essential Cookies</strong></p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies are essential to provide You with services available through the Website and
                    to enable You to use some of its features. They help to authenticate users and prevent fraudulent
                    use of user accounts. Without these Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with those services.</p>
            </li>
            <li>
                <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
            </li>
            <li>
                <p><strong>Functionality Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                    remembering your login details or language preference. The purpose of these Cookies is to provide
                    You with a more personal experience and to avoid You having to re-enter your preferences every time
                    You use the Website.</p>
            </li>
        </ul>
        <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies
            Policy or the Cookies section of our Privacy Policy.</p>
        <h3 class="paddingSmall">Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li>
                <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
                </p>
            </li>
            <li>
                <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
                    Personal Data You provide can give You access to different functionalities of the Service that are
                    available to You as a registered user.</p>
            </li>
            <li>
                <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of
                    the purchase contract for the products, items or services You have purchased or of any other
                    contract with Us through the Service.</p>
            </li>
            <li>
                <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                    forms of electronic communication, such as a mobile application's push notifications regarding
                    updates or informative communications related to the functionalities, products or contracted
                    services, including the security updates, when necessary or reasonable for their implementation.</p>
            </li>
            <li>
                <p><strong>To provide You</strong> with news, special offers and general information about other goods,
                    services and events which we offer that are similar to those that you have already purchased or
                    enquired about unless You have opted not to receive such information.</p>
            </li>
            <li>
                <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
            </li>
            <li>
                <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of
                    Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
                    in which Personal Data held by Us about our Service users is among the assets transferred.</p>
            </li>
            <li>
                <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data
                    analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and
                    to evaluate and improve our Service, products, services, marketing and your experience.</p>
            </li>
        </ul>
        <p>We may share Your personal information in the following situations:</p>
        <ul>
            <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers
                to monitor and analyze the use of our Service, to contact You.</li>
            <li><strong>For business transfers:</strong> We may share or transfer Your personal information in
                connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of Our business to another company.</li>
            <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we
                will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and
                any other subsidiaries, joint venture partners or other companies that We control or that are under
                common control with Us.</li>
            <li><strong>With business partners:</strong> We may share Your information with Our business partners to
                offer You certain products, services or promotions.</li>
            <li><strong>With other users:</strong> when You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by all users and may be publicly
                distributed outside.</li>
            <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with
                Your consent.</li>
        </ul>
        <h3 class="paddingSmall">Retention of Your Personal Data</h3>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
            disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
            a shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        <h3 class="paddingSmall">Transfer of Your Personal Data</h3>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other
            places where the parties involved in the processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
            to that transfer.</p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including the security of Your data and other
            personal information.</p>
        <h3 class="paddingSmall">Delete Your Personal Data</h3>
        <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected
            about You.</p>
        <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
        <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one,
            and visiting the account settings section that allows you to manage Your personal information. You may also
            contact Us to request access to, correct, or delete any personal information that You have provided to Us.
        </p>
        <p>Please note, however, that We may need to retain certain information when we have a legal obligation or
            lawful basis to do so.</p>
        <h3 class="paddingSmall">Disclosure of Your Personal Data</h3>
        <h4>Business Transactions</h4>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
            will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
            Policy.</p>
        <h4>Law enforcement</h4>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
            by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        <h4 class="paddingSmall">Other legal requirements</h4>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
        </ul>
        <h3 class="paddingSmall">Security of Your Personal Data</h3>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
            means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
        <h2 class="paddingSmall">Children's Privacy</h2>
        <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
            child has provided Us with Personal Data, please contact Us. If We become aware that We have collected
            Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.</p>
        <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
            consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
        <h2 class="paddingSmall">Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
            link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy
            of every site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.</p>
        <h2 class="paddingSmall">Changes to this Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
            Privacy Policy on this page.</p>
        <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
            effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.</p>
        <h2 class="paddingSmall">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul>
            <li>By email: info(at)florian-schoenfelder.de</li>
        </ul>
    </div>



</section>

<img class="ball" src="./assets/img/blue-ball.png">



<!-- German below -->


<section *ngIf="!translation">

    <div>
        <h1>Datenschutzrichtlinie</h1>
        <p>Zuletzt aktualisiert: 29. Juli 2024</p>
        <p>Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und Verfahren zur Erfassung, Verwendung und
            Offenlegung
            Ihrer Daten bei der Nutzung des Dienstes und informiert Sie über Ihre Datenschutzrechte und wie das Gesetz
            Sie
            schützt.</p>
        <p>Wir verwenden Ihre personenbezogenen Daten, um den Dienst bereitzustellen und zu verbessern. Durch die
            Nutzung
            des Dienstes stimmen Sie der Erfassung und Verwendung von Informationen gemäß dieser Datenschutzrichtlinie
            zu.
            Diese Datenschutzrichtlinie wurde mit Hilfe des <a
                href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">kostenlosen
                Datenschutzrichtlinien-Generators</a> erstellt.</p>
        <h2>Auslegung und Definitionen</h2>
        <h3>Auslegung</h3>
        <p>Die Wörter, deren Anfangsbuchstabe groß geschrieben ist, haben unter den folgenden Bedingungen definierte
            Bedeutungen. Die folgenden Definitionen haben dieselbe Bedeutung, unabhängig davon, ob sie im Singular oder
            im
            Plural stehen.</p>
        <h3>Definitionen</h3>
        <p>Für die Zwecke dieser Datenschutzrichtlinie gilt:</p>
        <ul>
            <li>
                <p><strong>Konto</strong> bezeichnet ein einzigartiges Konto, das für Sie erstellt wird, um auf unseren
                    Service oder Teile unseres Service zuzugreifen.</p>
            </li>
            <li>
                <p><strong>Tochtergesellschaft</strong> bezeichnet eine Einheit, die eine Partei kontrolliert, von einer
                    Partei kontrolliert wird oder unter gemeinsamer Kontrolle mit einer Partei steht, wobei „Kontrolle“
                    bedeutet Besitz von 50 % oder mehr der Aktien, Beteiligungen oder anderen Wertpapiere, die zur Wahl
                    von
                    Direktoren oder anderen Leitungsorganen berechtigt sind.</p>
            </li>
            <li>
                <p><strong>Unternehmen</strong> (in dieser Vereinbarung entweder als „das Unternehmen“, „Wir“, „Uns“
                    oder
                    „Unser“ bezeichnet) bezieht sich auf Portfolio.</p>
            </li>
            <li>
                <p><strong>Cookies</strong> sind kleine Dateien, die von einer Website auf Ihrem Computer, Mobilgerät
                    oder
                    einem anderen Gerät abgelegt werden und die unter anderem die Details Ihres Browserverlaufs auf
                    dieser
                    Website enthalten.</p>
            </li>
            <li>
                <p><strong>Land</strong> bezieht sich auf: Niedersachsen, Deutschland</p>
            </li>
            <li>
                <p><strong>Gerät</strong> bezeichnet jedes Gerät, das auf den Dienst zugreifen kann, wie z. B. ein
                    Computer,
                    ein Mobiltelefon oder ein digitales Tablet.</p>
            </li>
            <li>
                <p><strong>Personenbezogene Daten</strong> sind alle Informationen, die sich auf eine identifizierte
                    oder
                    identifizierbare Person beziehen.</p>
            </li>
            <li>
                <p><strong>Dienst</strong> bezieht sich auf die Website.</p>
            </li>
            <li>
                <p><strong>Dienstanbieter</strong> bezeichnet jede natürliche oder juristische Person, die die Daten im
                    Auftrag des Unternehmens verarbeitet. Es bezieht sich auf Drittunternehmen oder Einzelpersonen, die
                    vom
                    Unternehmen beschäftigt werden, um den Service zu ermöglichen, den Service im Namen des Unternehmens
                    bereitzustellen, mit dem Service verbundene Dienstleistungen zu erbringen oder das Unternehmen bei
                    der
                    Analyse der Nutzung des Service zu unterstützen.</p>
            </li>
            <li>
                <p><strong>Nutzungsdaten</strong> beziehen sich auf automatisch erfasste Daten, die entweder durch die
                    Nutzung des Service oder durch die Service-Infrastruktur selbst generiert werden (z. B. die Dauer
                    eines
                    Seitenbesuchs).</p>
            </li>
            <li>
                <p><strong>Website</strong> bezieht sich auf Portfolio, zugänglich über <a
                        href="https://florian-schoenfelder.de/" rel="external nofollow noopener"
                        target="_blank">https://florian-schoenfelder.de/</a></p>
            </li>
            <li>
                <p><strong>Sie</strong> bezeichnet die Person, die auf den Service zugreift oder ihn nutzt, oder das
                    Unternehmen oder eine andere juristische Person, in deren Namen diese Person auf den Service
                    zugreift
                    oder ihn nutzt, anwendbar.</p>
            </li>
        </ul>
        <h2>Erfassung und Verwendung Ihrer personenbezogenen Daten</h2>
        <h3>Arten der erfassten Daten</h3>
        <h4>Personenbezogene Daten</h4>
        <p>Bei der Nutzung unseres Dienstes bitten wir Sie möglicherweise, uns bestimmte personenbezogene Daten zur
            Verfügung zu stellen, die verwendet werden können, um Sie zu kontaktieren oder zu identifizieren.
            Personenbezogene Daten können Folgendes umfassen, sind aber nicht darauf beschränkt:</p>
        <ul>
            <li>
                <p>E-Mail-Adresse</p>
            </li>
            <li>
                <p>Vor- und Nachname</p>
            </li>
            <li>
                <p>Telefonnummer</p>
            </li>
            <li>
                <p>Nutzungsdaten</p>
            </li>
        </ul>
        <h4>Nutzungsdaten</h4>
        <p>Nutzungsdaten werden bei der Nutzung des Dienstes automatisch erfasst.</p>
        <p>Nutzungsdaten können Informationen wie die Internetprotokolladresse (z. B. IP-Adresse) Ihres Geräts,
            Browsertyp,
            Browserversion, die von Ihnen besuchten Seiten unseres Dienstes, Uhrzeit und Datum Ihres Besuchs, die auf
            diesen
            Seiten verbrachte Zeit, eindeutige Gerätekennungen und andere Diagnosedaten umfassen.</p>
        <p>Wenn Sie per oder über ein Mobilgerät auf den Dienst zugreifen, erfassen wir möglicherweise automatisch
            bestimmte
            Informationen, einschließlich, aber nicht beschränkt auf den Typ des von Ihnen verwendeten Mobilgeräts, die
            eindeutige ID Ihres Mobilgeräts, die IP Adresse Ihres Mobilgeräts, Ihr mobiles Betriebssystem, die Art des
            von
            Ihnen verwendeten mobilen Internetbrowsers, eindeutige Gerätekennungen und andere Diagnosedaten.</p>
        <p>Wir können auch Informationen erfassen, die Ihr Browser sendet, wenn Sie unseren Dienst besuchen oder wenn
            Sie
            über ein Mobilgerät auf den Dienst zugreifen.</p>
        <h4>Tracking-Technologien und Cookies</h4>
        <p>Wir verwenden Cookies und ähnliche Tracking-Technologien, um die Aktivität auf unserem Dienst zu verfolgen
            und
            bestimmte Informationen zu speichern. Als Tracking-Technologien werden Beacons, Tags und Skripte verwendet,
            um
            Informationen zu sammeln und zu verfolgen und unseren Dienst zu verbessern und zu analysieren. Zu den von
            uns
            verwendeten Technologien können gehören:</p>
        <ul>
            <li><strong>Cookies oder Browser-Cookies.</strong> Ein Cookie ist eine kleine Datei, die auf Ihrem Gerät
                abgelegt wird. Sie können Ihren Browser anweisen, alle Cookies abzulehnen oder anzugeben, wann ein
                Cookie
                gesendet wird. Wenn Sie jedoch keine Cookies akzeptieren, können Sie möglicherweise einige Teile unseres
                Dienstes nicht nutzen. Sofern Sie Ihre Browsereinstellungen nicht so angepasst haben, dass Cookies
                abgelehnt
                werden, kann unser Dienst Cookies verwenden.</li>
            <li><strong>Web Beacons.</strong> Bestimmte Abschnitte unseres Dienstes und unserer E-Mails können kleine
                elektronische Dateien enthalten, die als Web Beacons (auch als Clear Gifs, Pixel Tags und Single-Pixel
                Gifs
                bezeichnet) bezeichnet werden und es dem Unternehmen beispielsweise ermöglichen, die Anzahl der Benutzer
                zu
                zählen, die diese Seiten besucht oder eine E-Mail geöffnet haben, und andere damit verbundene
                Website-Statistiken zu erstellen (beispielsweise die Popularität eines bestimmten Abschnitts
                aufzuzeichnen
                und die System- und Serverintegrität zu überprüfen).</li>
        </ul>
        <p>Cookies können „dauerhafte“ oder „Sitzungs“-Cookies sein. Dauerhafte Cookies verbleiben auf Ihrem PC oder
            Mobilgerät, wenn Sie offline gehen, während Sitzungscookies gelöscht werden, sobald Sie Ihren Webbrowser
            schließen. Weitere Informationen zu Cookies finden Sie im Artikel der <a
                href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
                target="_blank">Website zur kostenlosen Datenschutzrichtlinie</a>.</p>
        <p>Wir verwenden sowohl Sitzungs- als auch dauerhafte Cookies für die unten aufgeführten Zwecke:</p>
        <ul>
            <li>
                <p><strong>Notwendige/wesentliche Cookies</strong></p>
                <p>Typ: Sitzungscookies</p>
                <p>Verwaltet von: Uns</p>
                <p>Zweck: Diese Cookies sind unerlässlich, um Ihnen die über die Website verfügbaren Dienste
                    bereitzustellen
                    und Ihnen die Nutzung einiger ihrer Funktionen zu ermöglichen. Sie helfen bei der Authentifizierung
                    von
                    Benutzern und verhindern die betrügerische Verwendung von Benutzerkonten. Ohne diese Cookies können
                    die
                    von Ihnen angeforderten Dienste nicht bereitgestellt werden. Wir verwenden diese Cookies nur, um
                    Ihnen
                    diese Dienste bereitzustellen.</p>
            </li>
            <li>
                <p><strong>Cookies für die Cookie-Richtlinie/Zustimmungs-Cookies</strong></p>
                <p>Typ: Dauerhafte Cookies</p>
                <p>Verwaltet von: Uns</p>
                <p>Zweck: Diese Cookies identifizieren, ob Benutzer die Verwendung von Cookies auf der Website
                    akzeptiert
                    haben.</p>
            </li>
            <li>
                <p><strong>Funktionalitäts-Cookies</strong></p>
                <p>Typ: Dauerhafte Cookies</p>
                <p>Verwaltet von: Uns</p>
                <p>Zweck: Diese Cookies ermöglichen es uns, Entscheidungen zu speichern, die Sie bei der Nutzung der
                    Website
                    treffen, z. B. Ihre Anmeldedaten oder Spracheinstellungen. Der Zweck dieser Cookies besteht darin,
                    Ihnen
                    ein persönlicheres Erlebnis zu bieten und zu vermeiden, dass Sie Ihre Präferenzen bei jeder Nutzung
                    der
                    Website erneut eingeben müssen.</p>
            </li>
        </ul>
        <p>Weitere Informationen zu den von uns verwendeten Cookies und Ihren Auswahlmöglichkeiten in Bezug auf Cookies
            finden Sie in unserer Cookie-Richtlinie oder im Abschnitt Cookies unserer Datenschutzrichtlinie.</p>
        <h3 class="paddingSmall">Verwendung Ihrer personenbezogenen Daten</h3>
        <p>Das Unternehmen kann personenbezogene Daten für die folgenden Zwecke verwenden:</p>
        <ul>
            <li>
                <p><strong>Zur Bereitstellung und Aufrechterhaltung unseres Dienstes</strong>, einschließlich der
                    Überwachung der Nutzung unseres Dienstes.</p>
            </li>
            <li>
                <p><strong>Zur Verwaltung Ihres Kontos:</strong> zur Verwaltung Ihrer Registrierung als Benutzer des
                    Dienstes. Die von Ihnen bereitgestellten personenbezogenen Daten können Ihnen Zugriff auf
                    verschiedene
                    Funktionen des Dienstes gewähren, die Ihnen als registrierter Benutzer zur Verfügung stehen.</p>
            </li>
            <li>
                <p><strong>Zur Erfüllung eines Vertrags:</strong> die Entwicklung, Einhaltung und Durchführung des
                    Kaufvertrags für die von Ihnen erworbenen Produkte, Artikel oder Dienstleistungen oder eines anderen
                    Vertrags mit uns über den Dienst.</p>
            </li>
            <li>
                <p><strong>Um Sie zu kontaktieren:</strong> Um Sie per E-Mail, Telefonanruf, SMS oder anderen
                    gleichwertigen
                    Formen der elektronischen Kommunikation zu kontaktieren, wie z. B. Push-Benachrichtigungen einer
                    mobilen
                    Anwendung bezüglich Updates oder informativer Mitteilungen in Bezug auf die Funktionen, Produkte
                    oder
                    vertraglich vereinbarten Dienstleistungen, einschließlich der Sicherheitsupdates, wenn dies für
                    deren
                    Umsetzung erforderlich oder angemessen ist.</p>
            </li>
            <li>
                <p><strong>Um Sie</strong> mit Neuigkeiten, Sonderangeboten und allgemeinen Informationen über andere
                    von
                    uns angebotene Waren, Dienstleistungen und Veranstaltungen zu versorgen, die denen ähnlich sind, die
                    Sie
                    bereits gekauft oder nach denen Sie gefragt haben, es sei denn, Sie haben sich gegen den Erhalt
                    solcher
                    Informationen entschieden. Informationen.</p>
            </li>
            <li>
                <p><strong>Zur Verwaltung Ihrer Anfragen:</strong> Zur Bearbeitung und Verwaltung Ihrer Anfragen an uns.
                </p>
            </li>
            <li>
                <p><strong>Für Geschäftsübertragungen:</strong> Wir können Ihre Informationen verwenden, um eine Fusion,
                    Veräußerung, Umstrukturierung, Reorganisation, Auflösung oder einen anderen Verkauf oder eine andere
                    Übertragung einiger oder aller unserer Vermögenswerte zu bewerten oder durchzuführen, sei es als
                    fortgeführter Betrieb oder als Teil eines Insolvenz-, Liquidations- oder ähnlichen Verfahrens, bei
                    dem
                    von uns über unsere Servicebenutzer gespeicherte personenbezogene Daten zu den übertragenen
                    Vermögenswerten gehören.</p>
            </li>
            <li>
                <p><strong>Für andere Zwecke</strong>: Wir können Ihre Informationen für andere Zwecke verwenden, wie
                    etwa
                    zur Datenanalyse, zur Ermittlung von Nutzungstrends, zur Bestimmung der Wirksamkeit unserer
                    Werbekampagnen und zur Bewertung und Verbesserung unseres Dienstes, unserer Produkte, Dienste,
                    unseres
                    Marketings und Ihrer Erfahrung.</p>
            </li>
        </ul>
        <p>Wir können Ihre personenbezogenen Daten in den folgenden Situationen weitergeben:</p>
        <ul>
            <li><strong>An Dienstanbieter:</strong> Wir können Ihre personenbezogenen Daten an Dienstanbieter
                weitergeben,
                um die Nutzung unseres Dienstes zu überwachen und zu analysieren und um mit Ihnen Kontakt aufzunehmen.
            </li>
            <li><strong>Für Geschäftsübertragungen:</strong> Wir können Ihre personenbezogenen Daten im Zusammenhang mit
                oder während der Verhandlungen über eine Fusion, einen Verkauf von Unternehmensvermögen, eine
                Finanzierung
                oder den Erwerb unseres gesamten oder eines Teils unseres Geschäfts durch ein anderes Unternehmen
                weitergeben oder übertragen.</li>
            <li><strong>An verbundene Unternehmen:</strong> Wir können Ihre Informationen an unsere verbundenen
                Unternehmen
                weitergeben. In diesem Fall verlangen wir von diesen verbundenen Unternehmen, diese
                Datenschutzrichtlinie
                einzuhalten. Zu den verbundenen Unternehmen zählen unsere Muttergesellschaft und alle anderen
                Tochtergesellschaften, Joint-Venture-Partner oder andere Unternehmen, die wir kontrollieren oder die
                unter
                gemeinsamer Kontrolle mit uns stehen.</li>
            <li><strong>Mit Geschäftspartnern:</strong> Wir können Ihre Informationen an unsere Geschäftspartner
                weitergeben, um Ihnen bestimmte Produkte, Dienstleistungen oder Werbeaktionen anzubieten.</li>
            <li><strong>Mit anderen Benutzern:</strong> Wenn Sie persönliche Informationen weitergeben oder auf andere
                Weise
                in den öffentlichen Bereichen mit anderen Benutzern interagieren, können diese Informationen von allen
                Benutzern angezeigt und öffentlich außerhalb verbreitet werden.</li>
            <li><strong>Mit Ihrer Zustimmung</strong>: Mit Ihrer Zustimmung können wir Ihre persönlichen Daten für jeden
                anderen Zweck offenlegen.</li>
        </ul>
        <h3 class="paddingSmall">Aufbewahrung Ihrer persönlichen Daten</h3>
        <p>Das Unternehmen wird Ihre persönlichen Daten nur so lange aufbewahren, wie es für die in dieser
            Datenschutzrichtlinie dargelegten Zwecke erforderlich ist. Wir werden Ihre personenbezogenen Daten in dem
            erforderlichen Umfang aufbewahren und verwenden, um unseren gesetzlichen Verpflichtungen nachzukommen
            (beispielsweise, wenn wir Ihre Daten aufbewahren müssen, um geltende Gesetze einzuhalten), Streitigkeiten
            beizulegen und unsere rechtlichen Vereinbarungen und Richtlinien durchzusetzen.</p>
        <p>Das Unternehmen wird Nutzungsdaten auch für interne Analysezwecke aufbewahren. Nutzungsdaten werden im
            Allgemeinen für einen kürzeren Zeitraum aufbewahrt, es sei denn, diese Daten werden verwendet, um die
            Sicherheit
            zu stärken oder die Funktionalität unseres Dienstes zu verbessern, oder wir sind gesetzlich verpflichtet,
            diese
            Daten für längere Zeiträume aufzubewahren.</p>
        <h3 class="paddingSmall">Übertragung Ihrer personenbezogenen Daten</h3>
        <p>Ihre Informationen, einschließlich personenbezogener Daten, werden in den Betriebsbüros des Unternehmens und
            an
            allen anderen Orten verarbeitet, an denen sich die an der Verarbeitung beteiligten Parteien befinden. Dies
            bedeutet, dass diese Informationen auf Computer außerhalb Ihres Staates, Ihrer Provinz, Ihres Landes oder
            einer
            anderen staatlichen Gerichtsbarkeit übertragen und dort gespeichert werden können, wo die Datenschutzgesetze
            von
            denen Ihrer Gerichtsbarkeit abweichen können.</p>
        <p>Ihre Zustimmung zu dieser Datenschutzrichtlinie und die anschließende Übermittlung dieser Informationen
            stellen
            Ihre Zustimmung zu dieser Übertragung dar.</p>
        <p>Das Unternehmen wird alle angemessenen Schritte unternehmen, um sicherzustellen, dass Ihre Daten sicher und
            in
            Übereinstimmung mit dieser Datenschutzrichtlinie behandelt werden. Es erfolgt keine Übertragung Ihrer
            personenbezogenen Daten an eine Organisation oder ein Land, sofern keine angemessenen Kontrollen vorhanden
            sind,
            einschließlich der Sicherheit Ihrer Daten und anderer personenbezogener Informationen.</p>
        <h3 class="paddingSmall">Löschen Ihrer personenbezogenen Daten</h3>
        <p>Sie haben das Recht, die personenbezogenen Daten, die wir über Sie gesammelt haben, zu löschen oder unsere
            Unterstützung beim Löschen zu verlangen.</p>
        <p>Unser Service bietet Ihnen möglicherweise die Möglichkeit, bestimmte Informationen über Sie innerhalb des
            Services zu löschen.</p>
        <p>Sie können Ihre Informationen jederzeit aktualisieren, ändern oder löschen, indem Sie sich bei Ihrem Konto
            anmelden (falls Sie eines haben). und besuchen Sie den Abschnitt „Kontoeinstellungen“, in dem Sie Ihre
            persönlichen Daten verwalten können. Sie können uns auch kontaktieren, um Zugriff auf alle persönlichen
            Daten
            anzufordern, die Sie uns zur Verfügung gestellt haben, diese zu korrigieren oder zu löschen.</p>
        <p>Bitte beachten Sie jedoch, dass wir möglicherweise bestimmte Informationen aufbewahren müssen, wenn wir dazu
            gesetzlich verpflichtet sind oder eine gesetzliche Grundlage haben.</p>
        <h3 class="paddingSmall">Offenlegung Ihrer persönlichen Daten</h3>
        <h4>Geschäftstransaktionen</h4>
        <p>Wenn das Unternehmen an einer Fusion, Übernahme oder einem Verkauf von Vermögenswerten beteiligt ist, können
            Ihre
            persönlichen Daten übertragen werden. Wir benachrichtigen Sie, bevor Ihre personenbezogenen Daten übertragen
            werden und einer anderen Datenschutzrichtlinie unterliegen.</p>
        <h4>Strafverfolgung</h4>
        <p>Unter bestimmten Umständen kann das Unternehmen verpflichtet sein, Ihre personenbezogenen Daten offenzulegen,
            wenn dies gesetzlich vorgeschrieben ist oder als Reaktion auf gültige Anfragen von Behörden (z. B. einem
            Gericht
            oder einer Regierungsbehörde).</p>
        <h4 class="paddingSmall">Andere gesetzliche Anforderungen</h4>
        <p>Das Unternehmen kann Ihre personenbezogenen Daten in dem guten Glauben offenlegen, dass eine solche Maßnahme
            erforderlich ist, um:</p>
        <ul>
            <li>einer gesetzlichen Verpflichtung nachzukommen</li>
            <li>Schutz und Verteidigung der Rechte oder des Eigentums des Unternehmens</li>
            <li>Verhinderung oder Untersuchung möglicher Verstöße im Zusammenhang mit dem Service</li>
            <li>Schutz der persönlichen Sicherheit der Benutzer des Service oder der Öffentlichkeit</li>
            <li>Schutz vor gesetzlicher Haftung</li>
        </ul>
        <h3 class="paddingSmall">Sicherheit Ihrer persönlichen Daten</h3>
        <p>Die Sicherheit Ihrer persönlichen Daten ist uns wichtig, aber denken Sie daran, dass keine Methode der
            Übertragung über das Internet oder Methode der elektronischen Speicherung 100 % sicher ist. Obwohl wir uns
            bemühen, kommerziell akzeptable Mittel zum Schutz Ihrer personenbezogenen Daten einzusetzen, können wir
            deren
            absolute Sicherheit nicht garantieren.</p>
        <h2 class="paddingSmall">Datenschutz für Kinder</h2>
        <p>Unser Service richtet sich nicht an Personen unter 13 Jahren. Wir erfassen wissentlich keine
            personenbezogenen
            Daten von Personen unter 13 Jahren. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind und wissen,
            dass
            Ihr Kind uns personenbezogene Daten zur Verfügung gestellt hat, kontaktieren Sie uns bitte. Wenn uns bekannt
            wird, dass wir personenbezogene Daten von Personen unter 13 Jahren ohne Überprüfung der elterlichen
            Zustimmung
            erfasst haben, ergreifen wir Maßnahmen, um diese Informationen von unseren Servern zu entfernen.</p>
        <p>Wenn wir uns auf die Zustimmung als Rechtsgrundlage für die Verarbeitung Ihrer Daten verlassen müssen und Ihr
            Land die Zustimmung eines Elternteils erfordert, benötigen wir möglicherweise die Zustimmung Ihres
            Elternteils,
            bevor wir diese Informationen erfassen und verwenden.</p>
        <h2 class="paddingSmall">Links zu anderen Websites</h2>
        <p>Unser Service kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf
            einen
            Link eines Drittanbieters klicken, werden Sie auf die Website dieses Drittanbieters weitergeleitet. Wir
            empfehlen Ihnen dringend, die Datenschutzrichtlinie jeder von Ihnen besuchten Website zu überprüfen.</p>
        <p>Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien
            oder
            -praktiken von Websites oder Diensten Dritter.</p>
        <h2 class="paddingSmall">Änderungen dieser Datenschutzrichtlinie</h2>
        <p>Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen
            informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.</p>
        <p>Wir werden Sie per E-Mail und/oder durch einen deutlichen Hinweis auf unserem Dienst informieren, bevor die
            Änderung wirksam wird, und das Datum „Zuletzt aktualisiert“ oben in dieser Datenschutzrichtlinie
            aktualisieren.
        </p>
        <p>Es wird Ihnen empfohlen, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen. Änderungen
            dieser
            Datenschutzrichtlinie werden wirksam, wenn sie auf dieser Seite veröffentlicht werden.</p>
        <h2 class="paddingSmall">Kontaktieren Sie uns</h2>
        <p>Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns kontaktieren:</p>
        <ul>
            <li>Per E-Mail: info(at)florian-schoenfelder.de</li>
        </ul>
    </div>

</section>